import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { useForm } from 'react-hook-form'
import {
  onCreateLease,
  onCreateLeaseArticle,
  onDeleteLeaseArticle,
  onUpdateLease
} from '@api/lease'
import { responseHandler } from '@utils/responseHandler'
import { useSnackbar } from 'notistack'
import { format, parse } from 'date-fns'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
// import debounce from 'lodash.debounce'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography
} from '@mui/material'

import RentAndExpensesStep from './rent-expenses/RentAndExpensesStep'
import DiscountsStep from './discounts/DiscountsStep'

import IndexationStep from './indexation/IndexationStep'

import { mutate } from 'swr'

import InvoicingRulesStep from './invoicing-rules/InvoicingRulesStep'
import queryString from '@utils/queryString'
import GeneralInfoStep from './general-information/GeneralInfoStep'
import DocumentsStep from './documents/DocumentsStep'
import { onCreateDiscount } from '@api/discounts'
import { Close } from '@mui/icons-material'
import numberWithCommas from '@utils/numberWithCommas'

export default function LeaseForm(props) {
  const {
    open,
    title,
    onClose,
    onCloseTooltip,
    operationId,
    leaseId = null,
    fetchLeasesURL,
    handleOpenLeaseDetails,
    isEdit,
    handleClose,
    currency
  } = props
  const { dictionary, t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedLeaseId, setSelectedLeaseId] = useState(leaseId)
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedLots, setSelectedLots] = useState([])
  const [articles, setArticles] = useState([])
  const [referenceArticle, setReferenceArticle] = useState([])
  const [periods, setPeriods] = useState([])

  const [discounts, setDiscounts] = useState([])
  const [discountTitle, setDiscountTitle] = useState()
  const [discountAmount, setDiscountAmount] = useState()
  const [discountDescription, setDiscountDescription] = useState()
  const [discountStartDate, setDiscountStartDate] = useState()
  const [discountEndDate, setDiscountEndDate] = useState()

  useEffect(() => {
    setSelectedLeaseId(leaseId)
  }, [leaseId])

  const leaseURL = selectedLeaseId
    ? API_URL.LEASES.GET_LEASE(selectedLeaseId)
    : null
  const { data } = useFetchData(leaseURL)

  const fetchTenantsURL =
    API_URL.TENANTS.GET_TENANTS_LIST() +
    '?' +
    queryString({ operations: operationId, page_size: 1000 })
  const { data: tenants } = useFetchData(fetchTenantsURL)

  const fetchArticlesURL = selectedLeaseId
    ? API_URL.LEASES.GET_LEASE_ARTICLES(selectedLeaseId)
    : null
  const { data: articlesData, isLoading: isArticlesLoading } =
    useFetchData(fetchArticlesURL)
  useEffect(
    () => setArticles(articlesData?.results),
    [articlesData, selectedLeaseId]
  )

  const fetchPeriodsURL = selectedLeaseId
    ? API_URL.LEASES.GET_LEASE_PERIODS(selectedLeaseId)
    : null
  const { data: periodsData, isLoading: isPeriodsLoading } =
    useFetchData(fetchPeriodsURL)
  useEffect(() => setPeriods(periodsData?.results), [periodsData])

  const fetchDiscountsURL = leaseId
    ? API_URL.DISCOUNTS.GET_DISCOUNT_LIST() +
      '?' +
      queryString({ lease: leaseId })
    : null
  const { data: discountsData, isLoading: isDiscountsLoading } =
    useFetchData(fetchDiscountsURL)
  useEffect(() => setDiscounts(discountsData?.results), [discountsData])

  const fetchLotsURL =
    API_URL.LOTS.GET_LOTS() +
    '?' +
    queryString({ operation: operationId, is_private: true })
  const { data: lotsData, isLoading: isLotsLoading } =
    useFetchData(fetchLotsURL)

  const {
    control,
    setError,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      tenant: data ? data.tenant?.id : '',
      make_deposit: false,
      deposit: 0,
      deposit_months: 0,
      is_indexed: true
    }
  })

  const watchTenant = watch('tenant')
  const watchStartDate = watch('start_date')
  const watchEndDate = watch('end_date')
  const watchMakeDeposit = watch('make_deposit')
  const watchRent = watch('total_monthly_rent')
  const watchIndexation = watch('is_indexed')
  const watchIndexationFields = watch([
    'is_indexed',
    'index_type',
    'index_base_year',
    'index_base_trimester'
  ])
  const watchInvoicingRulesFields = watch([
    'invoicing_method',
    'invoicing_frequency',
    'invoice_day',
    'billing_option',
    'document_type'
  ])
  const watchEmailFields = watch([
    'invoice_email_to',
    'invoice_email_cc',
    'invoice_email_bcc'
  ])

  useEffect(() => {
    const tenantSelected = tenants?.results?.find(
      tenant => tenant.id === watchTenant
    )

    if (tenantSelected) {
      setValue('invoice_email_to', tenantSelected.email)
    }
  }, [watchTenant, tenants])

  useEffect(() => {
    if (data && data.rent_data) {
      setValue(
        'total_monthly_rent',
        numberWithCommas(data.rent_data.rent_base / 12, 2)
      )
    }
  }, [data, setValue])

  useEffect(() => {
    if (isEdit && selectedLeaseId && data) {
      setValue('tenant', data.tenant?.id)

      setValue(
        'total_monthly_rent',
        numberWithCommas(data?.rent_data.rent_base / 12, 2)
      )

      setValue(
        'start_date',
        data.start_date
          ? parse(data.start_date, 'dd-MM-yyyy', new Date())
          : data.start_date
      )

      setValue(
        'end_date',
        data.end_date
          ? parse(data.end_date, 'dd-MM-yyyy', new Date())
          : data.end_date
      )

      if (data.deposit !== 0) {
        setValue('make_deposit', true)
        setValue('deposit_months', data.deposit_months)
        setValue('deposit', data.deposit)
      }

      if (data.guarantee) {
        setValue('guarantee_months', data.guarantee_months)
        setValue('guarantee', data.guarantee)
      }

      if (data) {
        setValue('is_indexed', data.is_indexed)
        setValue('index_type', data.index_type)
        setValue('index_base_year', data.index_base_year)
        setValue('index_base_trimester', data.index_base_trimester)
      }

      setValue('invoicing_method', data.invoicing_method)
      setValue('invoice_day', data.invoice_day)
      setValue('billing_option', data.billing_option)
      setValue('invoicing_frequency', data.invoicing_frequency)
      setValue('document_type', data.document_type)

      setValue('invoice_email_to', data.invoice_email_to)
      setValue('invoice_email_cc', data.invoice_email_cc)
      setValue('invoice_email_bcc', data.invoice_email_bcc)

      setValue(
        'date_signed',
        data.date_signed
          ? parse(data.date_signed, 'dd-MM-yyyy', new Date())
          : data.date_signed
      )
    }
  }, [data, isEdit, leaseId, setValue])

  const handleDepositMonthsChange = value => {
    setValue('deposit_months', value)
    setValue(
      'deposit',
      watchRent
        ? Math.round(((Number(value) * data?.rent_data.rent_base) / 12) * 100) /
            100
        : 0
    )
  }

  const handleDepositChange = value => {
    setValue('deposit', value)
    setValue(
      'deposit_months',
      watchRent
        ? Number((value / (data?.rent_data.rent_base / 12)).toFixed(2))
        : 0
    )
  }

  const handleGuaranteeMonthsChange = value => {
    setValue('guarantee_months', value)
    setValue(
      'guarantee',
      watchRent
        ? Math.round(((Number(value) * data?.rent_data.rent_base) / 12) * 100) /
            100
        : 0
    )
  }

  const handleGuaranteeChange = value => {
    setValue('guarantee', value)
    setValue(
      'guarantee_months',
      watchRent
        ? Number((value / (data?.rent_data.rent_base / 12)).toFixed(2))
        : 0
    )
  }

  const handlePreviousActiveStep = () => {
    setActiveStep(currentStep => currentStep - 1)
  }

  const handleNextActiveStep = async () => {
    setActiveStep(currentStep => currentStep + 1)
    await mutate(fetchLeasesURL)
    await mutate(leaseURL)
  }

  const onSubmitLeaseArticle = async (leaseId, payload) => {
    const response = await onCreateLeaseArticle(leaseId, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(API_URL.PROJECTS.GET_PROJECT_ONBOARDING(operationId))
        return response
      },
      dictionary,
      msgSuccess: dictionary.lease_article_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleSelectLots = async (
    leaseId,
    startDate,
    endDate,
    selectedLots
  ) => {
    setLoading(true)
    const payload = {
      lease_article: selectedLots
    }
    if (startDate) payload.start_date = startDate
    if (endDate) {
      payload.end_date = endDate
    }

    const promises = payload.lease_article.map(async lot => {
      payload.lot = lot
      if (referenceArticle.indexOf(lot) === -1) {
        const response = await onSubmitLeaseArticle(leaseId, payload)
        return response
      }
    })

    const createPromise = await Promise.all(promises)
    if (createPromise) {
      const promises = referenceArticle.map(async lot => {
        if (payload.lease_article.indexOf(lot) === -1) {
          let leaseArticleId = articlesData.results.filter(
            article => article.lot === lot
          )

          const response = await onDeleteLeaseArticle(
            leaseId,
            leaseArticleId[0]?.id
          )

          return response
        }
      })
      const deletePromise = await Promise.all(promises)
      if (deletePromise) {
        enqueueSnackbar(dictionary.lease_articles_updated, {
          variant: 'success'
        })
        mutate(fetchLotsURL)
        mutate(fetchArticlesURL)
      }
    }

    await mutate(API_URL.LEASES.GET_LEASE(leaseId))
    setLoading(false)
    //   handleClose?.()
  }

  const handleSubmitLease = async () => {
    setLoading(true)

    const payload = getValues()
    payload.operation = Number(operationId)
    if (payload.start_date) {
      payload.start_date = format(new Date(payload.start_date), 'dd-MM-yyyy')
    }
    if (payload.end_date) {
      payload.end_date = format(new Date(payload.end_date), 'dd-MM-yyyy')
    }
    if (payload.date_signed) {
      payload.date_signed = format(new Date(payload.date_signed), 'dd-MM-yyyy')
    }

    const response = await onCreateLease(payload)

    handleSelectLots(
      response?.data?.id,
      payload.start_date,
      payload.end_date,
      selectedLots
    )

    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)

    responseHandler({
      response,
      callback: () => {
        setSelectedLeaseId(response.data.id)
        setLoading(false)
        handleNextActiveStep()
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
        setLoading(false)
      },
      dictionary,
      msgSuccess: dictionary.lease_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleEditLease = async () => {
    setLoading(true)
    const payload = getValues()
    payload.operation = Number(operationId)
    if (payload.start_date) {
      payload.start_date = format(new Date(payload.start_date), 'dd-MM-yyyy')
    }
    if (payload.end_date) {
      payload.end_date = format(new Date(payload.end_date), 'dd-MM-yyyy')
    }
    if (payload.date_signed) {
      payload.date_signed = format(new Date(payload.date_signed), 'dd-MM-yyyy')
    }
    if (payload.guarantee === '') {
      payload.guarantee = 0
    }
    if (payload.guarantee_months === '') {
      payload.guarantee_months = 0
    }

    const response = await onUpdateLease(selectedLeaseId, payload)

    handleSelectLots(
      selectedLeaseId,
      payload.start_date,
      payload.end_date,
      selectedLots
    )

    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)

    responseHandler({
      response,
      callback: () => {
        setSelectedLeaseId(response.data.id)
        setLoading(false)
        handleNextActiveStep()
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
        setLoading(false)
      },
      dictionary,
      msgSuccess: dictionary.lease_updated,
      snackbar: enqueueSnackbar
    })
  }

  const handleSubmitDiscount = async () => {
    setLoading(true)
    const payload = {}
    payload.lease = selectedLeaseId
    payload.title = discountTitle
    payload.description = discountDescription
    payload.amount = discountAmount

    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)

    if (!discountAmount) {
      setLoading(false)
      handleNextActiveStep()
      return
    }
    if (discountStartDate) {
      payload.start_date = format(new Date(discountStartDate), 'dd-MM-yyyy')
    }
    if (discountEndDate) {
      payload.end_date = format(new Date(discountEndDate), 'dd-MM-yyyy')
    }

    const response = await onCreateDiscount(payload)

    responseHandler({
      response,
      callback: () => {
        setLoading(false)
        mutate(fetchDiscountsURL)
        mutate(API_URL.LEASES.GET_LEASE_SCHEDULE(leaseId))
        handleNextActiveStep()
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
        setLoading(false)
      },
      dictionary,
      msgSuccess: dictionary.lease_updated,
      snackbar: enqueueSnackbar
    })
  }

  const handleSubmitIndexation = async () => {
    setLoading(true)
    const [is_indexed, index_type, index_base_year, index_base_trimester] =
      watchIndexationFields
    const payload = {
      is_indexed: is_indexed,
      index_type,
      index_base_year,
      index_base_trimester
    }

    const response = await onUpdateLease(selectedLeaseId, payload)

    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)

    responseHandler({
      response,
      callback: () => {
        setLoading(false)
        handleNextActiveStep()
      },
      callbackError: () => {
        const { errors } = response.data
        if (errors?.length > 0) {
          errors.map(error => {
            setError(error.field, {
              message: error.message
            })
          })
        }
        setLoading(false)
      },
      dictionary,
      msgSuccess: dictionary.indexation_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleSubmitInvoicingRules = async () => {
    if (!watchInvoicingRulesFields) {
      handleNextActiveStep()
    } else {
      setLoading(true)

      const [
        invoicing_method,
        invoicing_frequency,
        invoice_day,
        billing_option,
        document_type
      ] = watchInvoicingRulesFields

      const [invoice_email_to, invoice_email_cc, invoice_email_bcc] =
        watchEmailFields

      const payload = {
        invoice_email_to,
        invoice_email_cc,
        invoice_email_bcc,
        invoicing_method,
        invoicing_frequency,
        invoice_day,
        billing_option,
        document_type
      }

      const response = await onUpdateLease(selectedLeaseId, payload)

      const newCompleted = completed
      newCompleted[activeStep] = true
      setCompleted(newCompleted)

      responseHandler({
        response,
        callback: () => {
          setLoading(false)
          handleNextActiveStep()
        },
        callbackError: () => {
          const { errors } = response.data
          if (errors.length > 0) {
            errors.map(error => {
              setError(error.field, {
                message: error.message
              })
            })
          }
          setLoading(false)
        },
        dictionary,
        msgSuccess: dictionary.invoicing_rules_created,
        snackbar: enqueueSnackbar
      })
    }
  }

  const handleComplete = async () => {
    await mutate(fetchLeasesURL)
    handleOpenLeaseDetails?.(selectedLeaseId)
    handleClose?.()
  }

  const getLots = lots => lots?.filter(lot => lot.lot).map(lot => lot.lot)

  useEffect(() => {
    if (articlesData && articlesData.results.length > 0) {
      setReferenceArticle(getLots(articlesData.results))
      setSelectedLots(getLots(articlesData.results))
    }
  }, [articlesData])

  const stepperTitle = watchIndexation
    ? [
        dictionary.general_information,
        dictionary.rent_and_expenses,
        dictionary.indexation,
        dictionary.discounts,
        dictionary.invoicing_rules,
        dictionary.documents
      ]
    : [
        dictionary.general_information,
        dictionary.rent_and_expenses,
        dictionary.discounts,
        dictionary.invoicing_rules,
        dictionary.documents
      ]

  const handleContentSteps = step => {
    switch (step) {
      case 0:
        return (
          <GeneralInfoStep
            operationId={operationId}
            leaseId={selectedLeaseId}
            control={control}
            setValue={setValue}
            setError={setError}
            errors={errors}
            defaultValue=""
            fullWidth={true}
            fetchTenantsURL={fetchTenantsURL}
            tenants={tenants}
            setSelectedLeaseId={setSelectedLeaseId}
            selectedLots={selectedLots}
            setSelectedLots={setSelectedLots}
            lotsData={lotsData}
            isLotsLoading={isLotsLoading}
            periods={periods}
            isPeriodsLoading={isPeriodsLoading}
            fetchPeriodsURL={fetchPeriodsURL}
            activeStep={activeStep}
            handleEndDate={date => setValue('end_date', date)}
            watchTenant={watchTenant}
          />
        )
      case 1:
        return (
          <RentAndExpensesStep
            operationId={operationId}
            control={control}
            errors={errors}
            leaseId={selectedLeaseId}
            articles={articles}
            leaseURL={leaseURL}
            fetchArticlesURL={fetchArticlesURL}
            fetchLeasesURL={fetchLeasesURL}
            isLoading={isArticlesLoading}
            startDate={watchStartDate}
            endDate={watchEndDate}
            currency={currency}
            watchMakeDeposit={watchMakeDeposit}
            setValue={setValue}
            handleDepositMonthsChange={handleDepositMonthsChange}
            handleGuaranteeMonthsChange={handleGuaranteeMonthsChange}
            handleDepositChange={handleDepositChange}
            handleGuaranteeChange={handleGuaranteeChange}
          />
        )
      case 2:
        return watchIndexation ? (
          <IndexationStep
            control={control}
            errors={errors}
            setValue={setValue}
            indexation={watchIndexation}
            indexationFields={watchIndexationFields}
          />
        ) : (
          <DiscountsStep
            leaseId={selectedLeaseId}
            startDate={watchStartDate}
            endDate={watchEndDate}
            currency={currency}
            discounts={discounts}
            isLoading={isDiscountsLoading}
            fetchDiscountsURL={fetchDiscountsURL}
            discountTitle={discountTitle}
            discountAmount={discountAmount}
            discountDescription={discountDescription}
            setDiscountTitle={setDiscountTitle}
            setDiscountAmount={setDiscountAmount}
            setDiscountDescription={setDiscountDescription}
            setDiscountStartDate={setDiscountStartDate}
            setDiscountEndDate={setDiscountEndDate}
          />
        )
      case 3:
        return watchIndexation ? (
          <DiscountsStep
            leaseId={selectedLeaseId}
            startDate={watchStartDate}
            endDate={watchEndDate}
            currency={currency}
            discounts={discounts}
            isLoading={isDiscountsLoading}
            fetchDiscountsURL={fetchDiscountsURL}
            discountTitle={discountTitle}
            discountAmount={discountAmount}
            discountDescription={discountDescription}
            setDiscountTitle={setDiscountTitle}
            setDiscountAmount={setDiscountAmount}
            setDiscountDescription={setDiscountDescription}
            setDiscountStartDate={setDiscountStartDate}
            setDiscountEndDate={setDiscountEndDate}
          />
        ) : (
          <InvoicingRulesStep
            control={control}
            isCompany={data?.tenant?.is_company}
            errors={errors}
          />
        )
      case 4:
        return watchIndexation ? (
          <InvoicingRulesStep
            control={control}
            isCompany={data?.tenant?.is_company}
            errors={errors}
          />
        ) : (
          <DocumentsStep leaseId={selectedLeaseId} />
        )
      case 5:
        return watchIndexation ? (
          <DocumentsStep leaseId={selectedLeaseId} />
        ) : null
      default:
        throw new Error('Unknown step')
    }
  }

  const [completed, setCompleted] = useState([])

  const handleSubmitStep = step => {
    switch (step) {
      case 0:
        return selectedLeaseId ? handleEditLease : handleSubmitLease
      case 1:
        return selectedLeaseId && handleEditLease
      case 2:
        return watchIndexation
          ? selectedLeaseId && handleSubmitIndexation
          : discounts?.length > 0
          ? handleEditLease
          : handleSubmitDiscount
      case 3:
        return watchIndexation
          ? discounts?.length > 0
            ? handleEditLease
            : handleSubmitDiscount
          : selectedLeaseId && handleSubmitInvoicingRules
      case 4:
        return watchIndexation
          ? selectedLeaseId && handleSubmitInvoicingRules
          : handleComplete
      case 5:
        return watchIndexation ? handleComplete : null
      default:
        throw new Error('Unknown step')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setActiveStep(0)
        onClose()
        reset()
        setSelectedLeaseId(null)
      }}
      fullWidth
      maxWidth={'lg'}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        }
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid
          container
          spacing={2}
          sx={{ borderRadius: '20px', height: '610px', overflowY: 'auto' }}
        >
          <Grid
            item
            xs={3}
            sx={{ height: '610px', width: '300px', position: 'absolute' }}
          >
            <Grid
              container
              paddingX={5}
              paddingY={10}
              height="100%"
              sx={{
                justifyContent: 'center',
                alignItems: 'start',
                backgroundColor: 'white',
                borderRadius: '20px'
              }}
            >
              <Stepper activeStep={activeStep} orientation="vertical">
                {stepperTitle.map((step, idx) => (
                  <Step
                    key={idx}
                    completed={
                      !isEdit && completed[idx] === undefined ? false : true
                    }
                    onClick={() => setActiveStep(idx)}
                  >
                    <StepLabel sx={{ '&:hover': { cursor: 'pointer' } }}>
                      <Typography
                        sx={{
                          '&:hover': { color: t => t.palette.secondary.dark }
                        }}
                        data-testid="Typography-27d885b0-7830-4613-b17a-f0a8acb79747"
                        fontSize={13}
                      >
                        {step}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{ marginLeft: '300px' }}>
            <Grid
              container
              height="100%"
              overflowY="auto"
              paddingX={activeStep === 0 ? 4 : 8}
              paddingY={9}
              sx={{ borderRadius: '20px', backgroundColor: 'white' }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  color="text.dark"
                  paddingX={activeStep === 0 ? 4 : 0}
                  mb={5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {title}
                  <Tooltip title={onCloseTooltip ? onCloseTooltip : t('close')}>
                    <IconButton
                      sx={{
                        mb: 2,
                        color: t => t.palette.secondary.dark
                      }}
                      onClick={handleClose}
                    >
                      <Close sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
                {handleContentSteps(activeStep)}
              </Grid>
              <Grid
                item
                xs={6}
                mt={5}
                pr={1.5}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {activeStep > 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handlePreviousActiveStep}
                    sx={{
                      height: 30,
                      width: 160,
                      borderRadius: '8px',
                      textTransform: 'none'
                    }}
                    data-testid="Button-4f0542e2-05a4-41fa-96be-064b6254d2b2"
                  >
                    {dictionary.previous}
                  </Button>
                ) : (
                  <div></div>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleSubmitStep(activeStep)}
                  sx={{
                    height: 30,
                    width: 160,
                    borderRadius: '8px',
                    textTransform: 'none'
                  }}
                  data-testid="Button-6c8f1492-0114-4d80-875b-7303e1843576"
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : activeStep === stepperTitle.length - 1 ? (
                    dictionary.submit
                  ) : (
                    dictionary.next
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
